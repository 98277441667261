import { Button } from '@mui/material';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Header from './Header';
import MainMenu from './MainMenu';
import './passwordStyle.css'


export default function Alert() {

  const [phrase, setPhrase] = useState("");

  if(phrase === 'House@1010') {
    return (
    
    <>
    <Header/>
    <MainMenu/>
    </>)
  }

  return (
    <div className="passwordStyle">
      <h2>Password</h2>
      <input
        type="password"
        value={phrase}
        onChange={(e) => setPhrase(e.target.value)}
        
      />
      
    </div>
  )
}