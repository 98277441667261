import { Collapse, Link, List, ListItem, ListItemText, Divider } from '@mui/material';
import React, {useState} from 'react';
import styled from 'styled-components';
import dataJson from './dataJson.json'
import { hasChildren } from './utils';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeviceHub from '@mui/icons-material/DeviceHub';
import './mainmenustyle.css'

const ListItemIcon = styled.span`

	  height: 30px;
    width: 5px;
	  padding-left: 3rem;
	  display: flex;
	  align-items: center;
	  text-decoration: none;
	  color: #f5f5f5;
	  font-size: 18px;
	
	  &:hover {
	    background: #632ce4;
	    cursor: pointer;
	  }

`;

const MainMenu = () => {

  return(
    <>
      {dataJson.map((item, index) => {     
        return  <MenuItem  item={item} key={index} /> 
      })}
    </>
  )
}

const MenuItem = ({ item }) => {
  
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;

  return(
     
    <Component className='barComponent' item={item} />

  );
}

const SingleLevel = ({ item }) => {
  return ( 
   
    <ListItem style={{ cursor:'pointer'}}>
       
      <ListItemIcon>{item.icon}  </ListItemIcon>
      
      <ListItemText primary={item.title} />
   
    </ListItem>
   
  );
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  console.log({children})
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };
  return ( 
    <React.Fragment>
      
      <ListItem  className='collapseList' button onClick={handleClick}>
       
        <ListItemIcon > {item.icon} </ListItemIcon>
        
        <ListItemText  primary={item.title} />
        
        {open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon  /> }
        
      </ListItem> 
  
      <Collapse className='collapseText'  in={open} timeout="auto" unmountOnExit> 
     
        <List className='collapseList' component='div' disablePadding >
        <Divider/>
          {children.map((child, key) => (
            <>
            <Divider style={{marginRight: '70px',  width: '200vw', display: 'flex'}} className='divider' />
            <MenuItem className='textAnimation' key={key} item={child} />
           
            </>
            
          ))}  
        </List>   
      
      </Collapse>
     
    </React.Fragment> 
  )
}

export default MainMenu;