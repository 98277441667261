import { ListItem, List, Divider } from "@mui/material";


export function hasChildren(item) {
  const { items: children } = item;

  if( children === undefined ) {
    return false;

  }
 
  if (children.constructor !== Array) {
   
    <ListItem style={{backgroundColor: 'red'}}/>
    
    return false;
  }
  if ( children.length === 0) {
    return false;

  }
 
  return true;
}