import { ListItemText } from '@mui/material';
import React from 'react';

 const Header = () => {
   return (
  <ListItemText>
   Logo Cargill
  </ListItemText>  
   )

}

export default Header;