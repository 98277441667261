import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import MainMenu from './components/MainMenu';
import Header from './components/Header';
import Alert from './components/Alert';

function App() {
  
  return (
    
   <Alert/>
   
  );
}

export default App;
